<template>
  <div ref="parent" class="group w-full">
    <HeadlessTab v-slot="{ selected }" as="template">
      <div
        :class="[
          'w-full py-2.5 text-center text-sm font-medium leading-5 md:h-full',
          'border transition-colors focus:outline-none group-first:rounded-l group-last:rounded-r',
          selected
            ? 'border-[#364A5D] bg-primaryDark text-white shadow'
            : 'border-[#C1C1C1] bg-white text-primary hover:bg-[#E5E8F0] hover:text-[#364A5D]'
        ]"
      >
        <div class="flex h-full flex-col items-center justify-center p-1">
          <slot name="header" />
        </div>
      </div>
    </HeadlessTab>
    <slot name="content" />
  </div>
</template>

<script setup lang="ts">
import { useAutoAnimate } from '@formkit/auto-animate/vue'

const [parent] = useAutoAnimate()
</script>
